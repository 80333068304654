import { CellType, ChargeabilityCell } from '../views/ChargeabilityCell'

export const ChargeabilityRowDataCells = ({
  rowDataValues,
  resourceInfo,
  hideColours,
  currentTimeSpan,
  enableTooltips,
}) => {
  return rowDataValues.map((cellValue, cellIdx) => (
    <ChargeabilityCell
      key={cellIdx}
      type={CellType.percent}
      isFirstInGroup={resourceInfo[cellIdx].isFirstInGroup}
      isFirstInTeam={resourceInfo[cellIdx].isFirstInTeam}
      heatmapPercentage={hideColours ? null : cellValue}
      currentTimeSpan={currentTimeSpan}
      tooltip={
        enableTooltips && currentTimeSpan
          ? 'warning - data is likely incomplete'
          : null
      }
    >
      {cellValue == null || isNaN(cellValue) || cellValue < 0
        ? null
        : cellValue.toLocaleString('en-NZ', {
          style: 'percent',
        })}
    </ChargeabilityCell>
  ))
}
