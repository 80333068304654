import { useCallback, useEffect, useMemo } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useDispatch, useSelector } from 'react-redux'

import Error from '../primitives/Error'
import Loading from '../primitives/Loading'
import {
  fetchChargeability as fetchChargeabilityAction,
  selectChargeability,
  selectLoadingState as selectChargeabilityLoadingState,
} from '../slices/chargeability'

import ChargeabilityContent from '../views/ChargeabilityContent'
import LoadingState from '../utils/LoadingState'

const ChargeabilityContainer = () => {
  const { getAccessTokenSilently: getTokenCallback } = useAuth0()
  const dispatch = useDispatch()

  const chargeability = useSelector(selectChargeability)
  const chargeabilityLoadingState = useSelector(selectChargeabilityLoadingState)
  const fetchChargeability = useCallback(() => {
    dispatch(fetchChargeabilityAction({ getTokenCallback }))
  }, [dispatch, getTokenCallback])

  useEffect(() => {
    if (chargeabilityLoadingState === LoadingState.idle) {
      fetchChargeability()
    }
  }, [
    fetchChargeability,
    chargeabilityLoadingState,
  ])
  
  document.title = 'Work Analysis'

  const chargeabilityData = useMemo(() =>
    chargeabilityLoadingState === LoadingState.fulfilled && chargeability
      ? chargeability
      : null
    , [chargeability, chargeabilityLoadingState])

  if (
    chargeabilityLoadingState === LoadingState.idle ||
    chargeabilityLoadingState === LoadingState.pending
  ) {
    return <Loading />
  } else if (chargeabilityLoadingState === LoadingState.rejected) {
    return <Error error={chargeability} retryCallback={fetchChargeability} />
  } else {
    return (
      <ChargeabilityContent {...chargeabilityData} />
    )
  }
}

export default ChargeabilityContainer
