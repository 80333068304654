import { useMemo, useState } from 'react'
import { Box } from '@mui/material'

import {
  createResourceHeaders,
  createTimeFrameHeaders,
} from '../utils/chargeabilityUtils'
import ChargeabilityControls from './ChargeabilityControls'
import ChargeabilityExportButton from './ChargeabilityExportButton'
import ChargeabilityTable from './ChargeabilityTable'
import PageTitle from '../primitives/PageTitle'

const CELL_MIN_WIDTH = 80

const ChargeabilityContent = ({
  chargeabilityMatrices,
  resourceHierarchy,
  timeFrameHierarchy,
}) => {
  const [adjustForLeave, setAdjustForLeave] = useState(true)
  const [resourceLevel, setResourceLevel] = useState('team')
  const [showColours, setShowColours] = useState(true)
  const [timeFrame, setTimeframe] = useState('week')

  const [maxCellWidth, setMaxCellWidth] = useState(CELL_MIN_WIDTH)

  const updateMaxWidth = (newPotentialWidth) => {
    setMaxCellWidth(prevMaxWidthValue => Math.max(prevMaxWidthValue, newPotentialWidth))
  }

  const onResourceLevelChanged = (resourceLevel) =>
  {
    setResourceLevel(resourceLevel)
    setMaxCellWidth(CELL_MIN_WIDTH) 
  }

  const aggregatedStructures = useMemo(() => {
    const resourceInfo = createResourceHeaders(resourceHierarchy, resourceLevel)
    const allRowDataHeaders = createTimeFrameHeaders(timeFrameHierarchy, timeFrame)
    return {
      resourceHierarchy,
      resourceInfo,
      allRowDataHeaders,
      allRowDataValues: chargeabilityMatrices[timeFrame][resourceLevel][adjustForLeave ? 1 : 0],
    }
  }, [chargeabilityMatrices, resourceHierarchy, timeFrameHierarchy, resourceLevel, timeFrame, adjustForLeave])

  return (
    <Box style={{width: "fit-content"}}>
      <Box sx={{ mb: 1, position: 'sticky', left: 88, width: '100%', maxWidth: `calc(100vw - 110px)`}}>
      <PageTitle>Work Analysis</PageTitle>
        <Box sx={{display: 'flex', flexDirection: 'row'}}>
          <ChargeabilityControls
            timeFrame={timeFrame}
            onTimeFrameChanged={setTimeframe}
            resourceLevel={resourceLevel}
            onResourceLevelChanged={onResourceLevelChanged}
            adjustForLeave={adjustForLeave}
            onAdjustForLeaveChanged={setAdjustForLeave}
            showColours={showColours}
            onShowColoursChanged={setShowColours}
          />
          <ChargeabilityExportButton
            aggregatedStructures={aggregatedStructures}
            resourceLevel={resourceLevel}
            timeFrame={timeFrame}
          />
        </Box>
      </Box>
      <ChargeabilityTable
        aggregatedStructures={aggregatedStructures}
        resourceLevel={resourceLevel}
        showColours={showColours}
        enableTooltips
        reversed
        cellWidth={maxCellWidth}
        updateMaxWidth={updateMaxWidth}
      />
    </Box>
  )
}
export default ChargeabilityContent
