import { configureStore } from '@reduxjs/toolkit'

import client from './slices/client'
import chargeability from './slices/chargeability'
import companyWorkbook from './slices/companyWorkbook'
import groups from './slices/groups'
import invoiceDetails from './slices/invoiceDetails'
import periods from './slices/periods'
import project from './slices/project'
import projectCandidates from './slices/projectCandidates'
import projectRates from './slices/projectRates'
import projects from './slices/projects'
import timeCostReport from './slices/timeCostReport'
import invoicingApproaches from './slices/invoicingApproaches'
import workItemMapping from './slices/workItemMapping'
import accessRights from './slices/accessRights'
import workbookSteps from './slices/workbookSteps'


export default configureStore({
  reducer: {
    client,
    chargeability,
    companyWorkbook,
    groups,
    invoiceDetails,
    periods,
    project,
    projectCandidates,
    projectRates,
    projects,
    timeCostReport,
    invoicingApproaches,
    workItemMapping,
    accessRights,
    workbookSteps,
  },
})
